import {Container} from "react-bootstrap";

const DocTermsArea = () => {
    return (
        <div id="DocTermsArea">
            <Container>
                <div className="docTerms__area">
                    <div className="docTerms__widget grey-bg-18">
                        <h1>이용약관</h1>
                        <br/><br/>
                        <h4>제 1 조 : 목적</h4>

                        <h4>제 2 조 : 이용계약의 성립</h4>

                        <h4>제 3 조 : 참가자의 의무</h4>

                        <h4>제 4 조 : 게시물 관리 및 삭제</h4>

                        <h4>제 5 조 : 게시물의 저작권</h4>

                        <h4>제 6 조 : 참가자 초상권의 사용</h4>

                        <h4>제 7 조 : 서비스 이용 책임</h4>

                        <h4>제 8 조 : 서비스 제공의 중지</h4>
                        <br/>

                        <h5>제 1 조 목적</h5>

                        <p>이 약관은 공개SW페스티벌(이하 "페스티벌"이라 한다) 홈페이지(ossfestival.kr)에서 제공하는 모든 서비스(이하 "서비스"라 한다)의 이용조건 및 절차에
                            관한
                            사항을 규정함을 목적으로 합니다. 사전등록을 통해 본 서비스의 참가자가 될 경우 본 약관 및 관련 운영 정책을 동의하신 것으로 봅니다.</p>


                        <h5>제 2 조 이용계약의 성립</h5>

                        <p>이용계약은 참가자의 사전등록에 대한 페스티벌의 승낙과 참가자의 약관 내용에 대한 동의로 성립됩니다.</p>

                        <p>사전등록은 참가자가 페스티벌에서 요구하는 사전등록 신청서 양식에 개인의 신상정보를 기록하여 신청할 수 있습니다.</p>

                        <p>페스티벌은 다음 각 호에 해당하는 참가자에 관하여 언제든 이용계약을 해지할 수 있습니다. 각 호에 해당하는 경우는 보통 약관 위배, 관계 법령 위배, 사회질서 저해에
                            해당합니다. 페스티벌는 이들의 잘못된 서비스 이용을 방지하기 위해 노력합니다. 다만 방지할 의무는 없으며, 따라서 방지하지 못한 책임을 지지 않습니다.</p>

                        <p>1) 만 14세 미만인 경우</p>

                        <p>2) 타인의 명의를 이용한 경우</p>

                        <p>3) 등록 내용에 허위 정보를 기재하거나 필수 정보가 누락되었을 경우</p>

                        <p>4) 부정한 용도를 목적으로 본 서비스를 이용하는 경우</p>

                        <p>5) 본 서비스가 제공하는 방법 외의 방법으로 영리를 취하려고 하는 경우</p>

                        <p>6) 관계법령에 위배되는 경우</p>

                        <p>7) 사회의 질서 혹은 미풍양속을 저해할 수 있는 사용자 생성 콘텐츠를 업로드하는 경우</p>

                        <p>8) 타인의 권리나 명예를 침해하는 경우</p>

                        <p>9) 본 약관 위배로 페스티벌에 의하여 이용계약이 해지된 경우 (페스티벌에서 재가입 승낙한 경우 제외)</p>


                        <h5>제 3 조 참가자의 의무</h5>

                        <p>참가자은 서비스를 이용할 때 다음 각 호의 행위를 하지 않아야 합니다.</p>

                        <p>1. 다른 참가자의 이메일을 부정하게 사용하는 행위</p>

                        <p>2. 서비스에서 얻은 정보를 복제, 출판 또는 제3자에게 제공하는 행위</p>

                        <p>3. 페스티벌의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</p>

                        <p>4. 공공질서 및 미풍양속에 위반되는 내용을 유포하는 행위</p>

                        <p>5. 범죄와 결부된다고 객관적으로 판단되는 행위</p>


                        <h5>제 4 조 게시물 관리 및 삭제</h5>

                        <p>효율적인 서비스 운영을 위하여 참가자의 질문 내용이 다음 각 호에 해당하는 경우에는 사전 통지없이 삭제할 수 있습니다.</p>

                        <p>1. 다른 참가자 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</p>

                        <p>2. 공공질서 및 미풍양속에 위반되는 내용인 경우</p>

                        <p>3. 범죄적 행위에 결부된다고 인정되는 내용인 경우</p>

                        <p>4. 페스티벌의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</p>

                        <p>5. 참가자가 페스티벌의 홈페이지와 게시판에 음란물을 게재하거나 음란 사이트를 링크하는 경우</p>

                        <p>6. 기타 관계법령에 위반된다고 판단되는 경우</p>


                        <h5>제 5 조 게시물의 저작권</h5>

                        <p>게시물의 저작권은 게시자 본인에게 있으며 참가자는 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 상업적으로 사용할 수 없습니다.</p>


                        <h5>제 6 조 : 참가자 초상권의 사용</h5>

                        <p>행사 사진 및 영상물에 포함된 참가자 초상권 등의 사용에 대한 동의 내용은 다음 각 호와 같습니다.</p>

                        <p>1. 페스티벌이 행사 사진 및 영상물에 대한 소유권, 저작권 기타 일체의 권리를 보유한다는 점에 동의하고, 참가자가 행사 사진 및 영상물에 대해 저작재산권 또는 기타
                            양도
                            가능한 권리를 보유하는 경우 그 권리를 법이 허용하는 범위에서 페스티벌에게 무상으로 이전합니다.</p>

                        <p> 2. 페스티벌 또는 페스티벌이 지정한 제3자가 [본 행사의 홍보 및 기록 등]의 목적으로 행사 사진 및 영상물에 포함된 참가자의 성명, 영상, 초상, 모습, 실연,
                            진술,
                            음성 및 인물정보(이하 통칭하여 “초상권 등”)를 [페스티벌의 웹사이트 및 간행물, 온ž오프라인 언론 매체]를 통해 공개하는 것에 동의합니다.</p>

                        <p>3. 페스티벌 또는 페스티벌이 지정한 제3자가 행사 사진 및 영상물의 성질, 이용 목적 및 형태에 비추어 필요하다고 판단하는 범위 내에서 참가자의 초상 등을 수정,
                            편집,
                            각색, 변형하거나 2차적 저작물을 작성하여 사용할 수 있다는 점에 동의합니다.</p>

                        <p>4. 페스티벌 또는 페스티벌이 지정한 제3자가 행사 사진 및 영상물에 포함된 참가자의 초상 등을 사용하는 행위에 대하여 참가자의 저작인격권, 초상권 또는 기타
                            참가자의
                            권리가 침해되었다는 일체의 주장 또는 소송을 제기하지 아니하기로 하는 것에 동의합니다.</p>


                        <h5>제 7 조 서비스 이용 책임</h5>

                        <p>서비스를 이용하여 해킹, 음란사이트 링크, 상용S/W 불법배포 등의 행위를 하여서는 안되며, 이를 위반으로 인해 발생한 영업활동의 결과 및 손실, 관계기관에 의한
                            법적 조치
                            등에 관하여는 페스티벌은 책임을 지지 않습니다.</p>


                        <h5>제 8 조 서비스 제공의 중지</h5>

                        <p>다음 각 호에 해당하는 경우에는 서비스 제공을 중지할 수 있습니다.</p>

                        <p>1. 홈페이지의 보수 등 공사로 인한 부득이한 경우</p>

                        <p>2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</p>

                        <p>3. 시스템 점검이 필요한 경우</p>

                        <p>4. 기타 불가항력적 사유가 있는 경우</p>


                        <h5>부 칙</h5>
                        <p>(시행일) 이 약관은 2023년 11월 01일부터 시행합니다.</p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default DocTermsArea;