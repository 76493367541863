const Terms2 = () => {
    return (
        <div id="Terms2">
            <h5>수집하는 개인정보의 항목</h5>

            <p>페스티벌은 등록을 위해 아래와 같은 개인정보를 수집하고 있습니다.</p>

            <p>ο 수집항목 : 이름, 소속, 이메일, 휴대폰 번호, 참여방법, 접속 로그</p>

            <p>ο 개인정보 수집방법 : 홈페이지(등록, Q&A 게시판 등)</p>

            <br/>
            <h5>개인정보의 수집 및 이용목적</h5>

            <p>페스티벌은 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>

            <p>ο 경품 제공에 관한 이행</p>

            <p>휴대폰 번호로 경품(기프티콘) 발송</p>

            <p>ο 등록 관리</p>

            <p>본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 고지사항 전달</p>

            <br/>
            <h5>개인정보의 보유 및 이용기간</h5>

            <p>ο 귀하의 개인정보는 개인정보 수집 및 이용목적이 달성된 후에는 예외 없이 해당 정보를 지체 없이 파기합니다.</p>

            <br/>
            <h5>수집한 개인정보의 위탁</h5>

            <p>페스티벌은 경품 발송을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다.</p>
        </div>
    );
};

export default Terms2;