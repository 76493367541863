const overall = {
    a:{
        time:1,
        title: '개회식 & 시상식',
        place: '401'
    },
    b:{
        time:2,
        title: '기조강연 1부,2부',
        place: '401'
    },
    b2:{
        time:2,
        title: '스프린트',
        place: '403'
    },
    c:{
        time:3,
        title: '발표세션',
        place: '401'
    },
    c2:{
        time:3,
        title: '패널토크',
        place: '402'
    },
    c3:{
        time:3,
        title: '스프린트',
        place: '403'
    },
    d:{
        time:4,
        title: '경품추첨 및 종료',
        place: ''
    },
}


const data401ho = [
    {

    }
]


export {overall}